/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef } from 'react';

import { SlotId } from '../../config/config';
import { EventTypes, GameMode, ISettledBet, IUserBalance } from '../../global.d';
import {
  configGql,
  getSlotGql,
  getUserGql,
  setBetAmount,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrentBonus,
  setGameMode,
  setIsErrorMessage,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsSpinInProgress,
  setIsTimeoutErrorMessage,
  setPrevReelsPosition,
  setReelSetId,
  setSlotConfig,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql';
import { IConfig, ISlotConfig } from '../../gql/d';
import SlotMachine from '../../slotMachine';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { ISlotData } from '../../slotMachine/d';
import { findSubstituteCoinAmount, getSpinResult, isFreeSpinMode, wrap } from '../../utils';
import { IPixiViewParentNode } from './d';
import styles from './slotMachineLayout.module.scss';

const SlotMachineLayout: React.FC = () => {
  const { data: clientData } = useQuery<IConfig>(configGql);
  const { isMiniPayTable } = clientData!;
  const { id } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const slotMachine = useRef<SlotMachine | null>(null);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);

  const { data } = useQuery<{ slot: ISlotData }, { input: { id: string } }>(
    getSlotGql,
    {
      variables: { input: { id } },
      onCompleted({ slot }) {
        const lines = slot.lines.map((_, index) => index);

        setSlotConfig({
          ...setSlotConfig(),
          clientSettings: slot.clientSettings,
          icons: slot.icons,
          reels: slot.reels,
          winLines: slot.lines,
          lines,
          lineSet: slot.lineSets[0],
        });

        // todo add logic to pick gamemode and reelsetid
        setReelSetId(slot.reels[0].id);
        setGameMode(GameMode.REGULAR);
        let coinValue;
        let coinAmount;
        if (setBrokenGame()) {
          const currentBonus = setCurrentBonus();
          coinValue = currentBonus.coinValue;
          coinAmount = currentBonus.coinAmount;
        } else {
          const lastBetCoinAmount = setUserLastBetResult().id ? setUserLastBetResult().coinAmount : 1;
          coinAmount = findSubstituteCoinAmount(lastBetCoinAmount, slot.clientSettings.coinAmounts.default);
          coinValue = slot.clientSettings.coinValues.find((elem) => elem.code === userData?.user.balance.currency)?.variants[0];
        }
        setCoinValue(coinValue);
        setCoinAmount(coinAmount);
        setWinAmount(setUserLastBetResult().result.winCoinAmount);
        setBetAmount(setCoinAmount() * lines.length);
        SlotMachine.initSlotMachine(
          slot,
          wrap(setIsSpinInProgress, false),
          wrap(setIsSlotBusy, false),
        );
        // Overriding these methods to avoid certified files.
        SlotMachine.getInstance().setResult = (result: ISettledBet) => {
          const spinResult = getSpinResult({
            reelPositions: result.bet.result.reelPositions.slice(0, 5),
            reelSet: result.bet.reelSet,
            icons: setSlotConfig().icons,
          });
          const newResult = {
            ...result,
            bet: {
              ...result.bet,
              result: {
                ...result.bet.result,
                spinResult,
              },
            },
          };
          SlotMachine.getInstance().nextResult = newResult;
          setUserLastBetResult(newResult.bet);
          setPrevReelsPosition(newResult.bet.result.reelPositions);
        
          if (!isFreeSpinMode(setGameMode())) {
            eventManager.emit(
              EventTypes.UPDATE_USER_BALANCE,
              SlotMachine.getInstance().nextResult?.balance.placed,
            );
          }
          if (result.bet.data.bonuses.length) {
            setCurrentBonus({
              ...setCurrentBonus(),
              rounds:
                result.bet.data.bonuses[0].rounds +
                result.bet.data.bonuses[0].roundsPlayed,
            });
          }
        };

        SlotMachine.getInstance().throwTimeoutError = () => {
          if (!setIsRevokeThrowingError()) {
            setIsTimeoutErrorMessage(true);
            setIsErrorMessage(true);
          }
          eventManager.emit(EventTypes.THROW_ERROR);
        };

        SlotMachine.getInstance().onSpinStop = () => {
          if (setIsErrorMessage()) {
            SlotMachine.getInstance().state = SlotMachineState.IDLE;
            eventManager.emit(EventTypes.DISABLE_PAY_TABLE, !isFreeSpinMode(setGameMode()));
            eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, SlotMachineState.IDLE);
            setIsSpinInProgress(false);
            setIsErrorMessage(false);
            // eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
          } else {
            wrap(setIsSpinInProgress, false)();
            SlotMachine.getInstance().miniPayTableContainer.setSpinResult(
              SlotMachine.getInstance().nextResult!.bet.result.spinResult,
            );
            SlotMachine.getInstance().state = SlotMachineState.JINGLE;
            eventManager.emit(EventTypes.DISABLE_PAY_TABLE, false);
            eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, SlotMachineState.JINGLE);
          }
        };

        slotMachine.current = SlotMachine.getInstance();
      },
    },
  );

  const resize = (application: PIXI.Application) => (): void => {
    const parent = application.view.parentNode as IPixiViewParentNode;
    const width = parent?.clientWidth;
    const height = parent?.clientHeight;
    eventManager.emit(EventTypes.RESIZE, width, height);
  };

  useEffect((): (() => void) | undefined => {
    if (slotMachine.current) {
      const application = slotMachine.current.getApplication();
      pixiContainerRef.current?.appendChild(
        slotMachine.current.getApplication().view,
      );
      resize(application)();
      window.addEventListener(EventTypes.RESIZE, resize(application));
      return () =>
        window.removeEventListener(EventTypes.RESIZE, resize(application));
    }
    return undefined;
  }, [!!data]);

  useEffect(() => {
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isMiniPayTable);
    if (!isMiniPayTable) {
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
    }
  }, [isMiniPayTable]);

  return <div className={styles.canvasWrapper} ref={pixiContainerRef} />;
};

export default React.memo(SlotMachineLayout);
