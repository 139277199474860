import * as PIXI from 'pixi.js';

import { isMobile } from 'mobile-device-detect';
import { EventTypes, GameMode } from '../../global.d';
import { setBrokenGame, setGameMode, setIsPortrait } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinMode } from '../../utils';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

class Background extends ViewContainer {
  private baseBg = PIXI.Texture.from(ResourceTypes.slotBg);

  private portraitBonusBg = PIXI.Texture.from(
    ResourceTypes.portraitSlotFreeSpinBg,
  );

  private bonusBg = PIXI.Texture.from(ResourceTypes.slotFreeSpinBg);

  private bgSprite = new PIXI.Sprite();

  private ambientBaseAnimation: SpineAnimation | null = null;

  constructor() {
    super();
    this.bgSprite.texture = setBrokenGame() ? this.bonusBg : this.baseBg;
    this.bgSprite.anchor.set(0.5);
    this.addChild(this.bgSprite);
    this.initAnimation();

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(
      EventTypes.CHANGE_MODE,
      this.changeModeBackground.bind(this),
    );
    eventManager.addListener(
      EventTypes.MANUAL_CHANGE_BACKGROUND,
      this.changeModeBackground.bind(this),
    );

    eventManager.addListener(
      EventTypes.CHANGE_DEVICE_ORIENTATION,
      (isPortrait: boolean, width: number, height: number) => {
        this.changeModeBackground({ mode: setGameMode() }, isPortrait);
        this.resize(width, height);
      },
    );

    if (!isFreeSpinMode(setGameMode()) && !setBrokenGame())
      this.startAnimation('base_ambient');
  }

  private initAnimation(): void {
    this.ambientBaseAnimation = new SpineAnimation(
      {},
      PIXI.Loader.shared.resources.ambient.spineData,
    );
    this.ambientBaseAnimation.addOnStart(() => {
      this.addChild(this.ambientBaseAnimation!.spine);
    });
  }

  private startAnimation(anim: string): void {
    this.ambientBaseAnimation!.setAnimation(anim, true);
    this.ambientBaseAnimation!.start();
  }

  private changeModeBackground(
    settings: { mode: GameMode },
    isPortrait = setIsPortrait(),
  ) {
    if (isFreeSpinMode(settings.mode)) {
      const isPortraitMode = isMobile && isPortrait;
      this.bgSprite.texture = isPortraitMode
        ? this.portraitBonusBg
        : this.bonusBg;
      this.startAnimation(
        isPortraitMode ? 'portrait_fs_ambient' : 'fs_ambient',
      );
    }
    if (settings.mode === GameMode.REGULAR) {
      this.bgSprite.texture = this.baseBg;
      this.startAnimation('base_ambient');
    }
  }

  private resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;

    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(height / this.bgSprite.height);
    } else {
      this.scale.set(width / this.bgSprite.width);
    }
  }
}
export default Background;
