/* eslint-disable react-hooks/exhaustive-deps */
import { ApolloClient, useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import AudioHowl from '@phoenix7dev/play-music';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';

import { config, ISongs, SlotId } from '../../config';
import {
  BetBonusReward,
  EventTypes,
  GameMode,
  GraphQLErrorsType,
  ISettledBet,
  IUserBalance,
} from '../../global.d';
import {
  configGql,
  getAutoSpinsGql,
  getBetAmountGql,
  getGameModeGql,
  getProgressGql,
  getUserGql,
  isStoppedGql,
  placeBetGql,
  replayBetGql,
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setBetAmount,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setGameHistory,
  setGameMode,
  setIsAutoSpins,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsFreeSpinsWin,
  setIsRevokeThrowingError,
  setIsShowSoundToast,
  setIsSlotBusy,
  setIsSoundLoading,
  setIsSpinInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setIsTimeoutErrorMessage,
  setLastRegularWinAmount,
  setReplayBet,
  setReplayFreeSpinBets,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setStressful,
  setWinAmount,
  stressfulGql,
} from '../../gql';
import { IConfig, ISlotConfig, IStressful } from '../../gql/d';
import SlotMachine from '../../slotMachine';
import { eventManager, SlotMachineState } from '../../slotMachine/config';
import {
  canPressSpin,
  formatNumber,
  getPlayListOnBroken,
  isFreeSpinMode,
  saveReelPosition,
  showCurrency,
} from '../../utils';
import { IPlaceBetInput } from './d';

let timeout: ReturnType<typeof setTimeout>;

const Spin: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { isTurboSpin } = data!;
  const { data: dataBet } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { data: stressful } = useQuery<{ stressful: IStressful }>(stressfulGql);
  const { id: slotId, lineSet, lines } = useReactiveVar<ISlotConfig>(
    setSlotConfig,
  );
  const isFreeSpinsWin = useReactiveVar<boolean>(setIsFreeSpinsWin);
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataProgress } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);
  const { data: dataSlotStopped } = useQuery<{ isSlotStopped: boolean }>(
    isStoppedGql,
  );

  const { data: gameModeData } = useQuery<{
    gameMode: GameMode;
  }>(getGameModeGql);
  const { gameMode } = gameModeData!;
  const balanceAmount = userData?.user.balance.amount || 0;
  const winThreeTimes = useReactiveVar<boolean[]>(setGameHistory);

  const { progress } = dataProgress!;

  const betCompleteCallback = (placeBet: ISettledBet, client: ApolloClient<any>): void => {
    eventManager.emit('placeBetCompleted');
    const clonnedPlaceBet: ISettledBet = JSON.parse(JSON.stringify(placeBet))
    if (clonnedPlaceBet.rewards) {
      const replayBonusIndex = clonnedPlaceBet?.rewards.findIndex(
        (reward) => reward.__typename === 'ReplayBonusReward',
      );
      if (replayBonusIndex > -1) {
        clonnedPlaceBet.rewards[replayBonusIndex].__typename = 'BetBonusReward'
      }
    }
    client.writeQuery({
      query: getUserGql,
      data: {
        ...userData,
        user: {
          ...userData?.user,
          balance: clonnedPlaceBet.balance.placed,
        },
      },
    });
    SlotMachine.getInstance().setResult(clonnedPlaceBet);
    if (SlotMachine.getInstance().isStopped) {
      SlotMachine.getInstance().spin(isTurboSpin);
    }
    if (isFreeSpinMode(setGameMode())) {
      eventManager.emit(EventTypes.SET_LAST_BET_RESULT_AFTER_FREE_SPINS);
    }

    const callBack = () => {
      const win = placeBet.bet.result.winCoinAmount;
      const lastThreeSpins = [...setGameHistory().slice(1), !!win];
      const thirdWinInRow = _.reduce(
        lastThreeSpins,
        (acc, item) => acc && item,
      );
      setGameHistory(lastThreeSpins);
      if (
        placeBet.bet.coinAmount * lines.length * 5 <= win &&
        !thirdWinInRow
      ) {
        AudioHowl.fadeIn(3000, ISongs.BaseGameBGM_Melo);
      }
      client.writeQuery({
        query: getUserGql,
        data: {
          ...userData,
          user: {
            ...userData?.user,
            balance: placeBet.balance.settled,
          },
        },
      });
      saveReelPosition(placeBet.bet.result.reelPositions);
    };
    SlotMachine.getInstance().setStopCallback(callBack.bind(this));
  };

  const { data: autoSpins } = useQuery<{
    isAutoSpins: boolean;
    autoSpinsLeft: number;
  }>(getAutoSpinsGql);
  const { isAutoSpins, autoSpinsLeft } = autoSpins!;
  const fadeOutCallback = () => {
    AudioHowl.fadeOut(3000, ISongs.BaseGameBGM_Melo);
  };

  const isFreeSpinModeOnTotalWinBannerStep: () => boolean = () =>
    isFreeSpinMode(setGameMode()) &&
    !setCurrentBonus().isActive &&
    setCurrentBonus().rounds === setCurrentBonus().currentRound;

  const [fnGet, { client }] = useMutation<
    { placeBet: ISettledBet },
    { input: IPlaceBetInput }
  >(placeBetGql, {
    onError(error) {
      eventManager.emit('placeBetCompleted');

      if (
        error.graphQLErrors.some(
          (err) =>
            err.extensions?.type === GraphQLErrorsType.INSUFFICIENT_FUNDS,
        )
      ) {
        eventManager.emit(EventTypes.RESET_SLOT_MACHINE);
        if (setIsAutoSpins()) setIsAutoSpins(false);
      }
    },
    async onCompleted({ placeBet }) {
      betCompleteCallback(placeBet, client);
    },
  });

  const [getReplayBet] = useLazyQuery<{ placeBet: ISettledBet }, { betId: string }>(replayBetGql, {
    async onCompleted({ placeBet }) {
      betCompleteCallback(placeBet, client);
    },
    onError(error) {
      eventManager.emit('placeBetCompleted');
    },
  });
  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      if (setIsRevokeThrowingError() || setIsTimeoutErrorMessage()) return;
      clearTimeout(timeout);
      setWinAmount(0);
      setLastRegularWinAmount(0);
      const spinState = SlotMachine.getInstance().state;
      if (
        spinState !== SlotMachineState.IDLE ||
        (!isFreeSpinMode(setGameMode()) && !setBrokenGame())
      ) {
        SlotMachine.getInstance().spin(isTurboSpin);
      }

      if (spinState === SlotMachineState.IDLE) {
        if (isFreeSpinMode(setGameMode()) || setBrokenGame()) return;

        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber(setCurrency(), 0, showCurrency(setCurrency())),
        );
        if (setIsAutoSpins()) setAutoSpinsLeft(setAutoSpinsLeft() - 1);
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });
        if (setReplayBet()) {
          getReplayBet({
            variables: { betId: setReplayBet() },
          });
        } else {
          fnGet({
            variables: {
              input: {
                slotId,
                coinAmount: setBetAmount() / 25,
                coinValue: setCoinValue(),
                lineSetId: lineSet.id,
              },
            },
          });
        }
        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        AudioHowl.stop({ type: ISongs.SFX_UI_Close });
        AudioHowl.play({ type: ISongs.SFX_UI_SpinStart });
      } else {
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: true,
          },
        });
      }

      if (AudioHowl.isRestricted) {
        const soundToPlay = isFreeSpinMode(setGameMode())
          ? getPlayListOnBroken(setIsDuringBigWinLoop())
          : [
            { type: ISongs.BaseGameBGM_Base },
            { type: ISongs.BaseGameBGM_Melo, volume: 0 },
          ];

        AudioHowl.changeRestriction(
          false,
          soundToPlay,
          () => setIsSoundLoading(true),
          () => setIsShowSoundToast(false),
        );
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    [dataBet?.betAmount, fnGet, lineSet, lines, slotId],
  );
  useEffect(() => {
    const freeSpin = () => {
      if (setIsRevokeThrowingError() || setIsTimeoutErrorMessage()) return;
      clearTimeout(timeout);
      SlotMachine.getInstance().spin(isTurboSpin);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      if (setReplayBet()) {
        const replayFreeSpins = setReplayFreeSpinBets();
        if (replayFreeSpins.length) {
          setReplayBet(replayFreeSpins[setCurrentBonus().currentRound - 1]);
        }

        getReplayBet({
          variables: { betId: setReplayBet() },
        });
      } else {
        fnGet({
          variables: {
            input: {
              slotId,
              coinAmount: setBetAmount() / 25,
              coinValue: setCoinValue(),
              lineSetId: lineSet.id,
              userBonusId: setCurrentBonusId(),
            },
          },
        });
      }
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioHowl.play({ type: ISongs.SFX_UI_SpinStart });
    };
    eventManager.on(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    return () => {
      eventManager.removeListener(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    };
  }, [onSpin, isTurboSpin]);

  const checkAutoSpinSettings = useCallback(() => {
    if (setIsAutoSpins() && !stressful?.stressful.show) {
      const autoSpinsLeft = setAutoSpinsLeft() <= 0;
      const bonus = setIsStopOnFeatureWin() && setCurrentBonus().isActive;
      const stopOnWin = setIsStopOnAnyWin() && setLastRegularWinAmount() > 0;

      const stopOnWinExceeds =
        setIsStopOnWinExceeds() &&
        setLastRegularWinAmount() >= setStopOnWinExceeds();

      const balanceIncrease =
        setIsStopOnBalanceIncrease() &&
        balanceAmount &&
        setStopOnBalanceIncrease() * setCoinValue() <=
        balanceAmount - setAutoSpinsStartBalance();

      const balanceDecrease =
        setIsStopOnBalanceDecrease() &&
        balanceAmount &&
        setStopOnBalanceDecrease() * setCoinValue() <=
        setAutoSpinsStartBalance() - balanceAmount;

      if (
        autoSpinsLeft ||
        bonus ||
        stopOnWin ||
        stopOnWinExceeds ||
        balanceIncrease ||
        balanceDecrease
      ) {
        setIsAutoSpins(false);
      } else {
        onSpin(isTurboSpin);
      }
    }
  }, [balanceAmount, onSpin, isTurboSpin, stressful?.stressful]);

  useEffect(() => {
    if (isAutoSpins && setIsFreeSpinsWin()) {
      if (setIsStopOnFeatureWin()) {
        setIsContinueAutoSpinsAfterFeature(false);
        setAutoSpinsLeft(0);
      } else {
        setIsContinueAutoSpinsAfterFeature(true);
      }
      setIsAutoSpins(false);
    }
  }, [isFreeSpinsWin, setIsContinueAutoSpinsAfterFeature()]);

  const onSpinButtonClick = useCallback(() => {
    if (setGameMode() === GameMode.REGULAR && setIsFreeSpinsWin()) {
      return;
    }
    if (isAutoSpins) {
      setAutoSpinsLeft(0);
      setIsAutoSpins(false);
    } else {
      onSpin(isTurboSpin);
    }
  }, [isAutoSpins, isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 32 && !stressful?.stressful.show) {
        e.preventDefault();
        e.stopPropagation();

        if (data?.isEnabledSpaceSpin) {
          eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
        }

        if (
          !canPressSpin({
            gameMode,
            isFreeSpinsWin: setIsFreeSpinsWin(),
            isSpinInProgress: setIsSpinInProgress(),
            isSlotBusy: setIsSlotBusy(),
            isSlotStopped: dataSlotStopped?.isSlotStopped ?? false,
          })
        ) {
          return;
        }

        if (data?.isEnabledSpaceSpin && isAutoSpins) {
          checkAutoSpinSettings();
          return;
        }
        if (
          data?.isEnabledSpaceSpin &&
          progress?.wasLoaded &&
          !isFreeSpinModeOnTotalWinBannerStep()
        ) {
          onSpin(isTurboSpin);
        }
      }
    },
    [
      gameMode,
      isAutoSpins,
      dataSlotStopped?.isSlotStopped,
      data?.isEnabledSpaceSpin,
      progress?.wasLoaded,
      checkAutoSpinSettings,
      onSpin,
      isTurboSpin,
      stressful?.stressful,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => window.removeEventListener('keydown', useHandleSpaceSpin);
  }, [useHandleSpaceSpin]);

  useEffect(() => {
    const play = _.reduce(winThreeTimes, (acc, item) => acc && item);
    const stop = !_.reduce(winThreeTimes, (acc, item) => acc || item);

    if (play) {
      AudioHowl.fadeIn(500, ISongs.BaseGameBGM_Melo);
    }

    if (stop) {
      AudioHowl.fadeOut(3000, ISongs.BaseGameBGM_Melo);
    } else {
      timeout = setTimeout(fadeOutCallback, 30000);
    }
  }, [winThreeTimes]);

  const isSlotBusy = useReactiveVar(setIsSlotBusy);
  useEffect(() => {
    let id: NodeJS.Timeout;
    if (!setIsFreeSpinsWin() && setIsContinueAutoSpinsAfterFeature()) {
      setIsAutoSpins(true);
      setIsContinueAutoSpinsAfterFeature(false);
    }
    if (dataSlotStopped?.isSlotStopped && !isSlotBusy) {
      id = setTimeout(
        () => {
          checkAutoSpinSettings();
        },
        setAutoSpinsLeft() === setAutoSpinsAmount()
          ? 0
          : config.autoplay.timeOut,
      );
    }
    return () => clearTimeout(id);
  }, [
    isAutoSpins,
    isFreeSpinsWin,
    checkAutoSpinSettings,
    dataSlotStopped?.isSlotStopped,
    isSlotBusy,
  ]);

  useEffect(() => {
    eventManager.on(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick, isAutoSpins, isTurboSpin]);

  return null;
};

export default Spin;
